<template>
    <section>
        <div class="row mx-0 align-items-center py-3 border-bottom">
            <div class="col-auto text-general f-600 f-17">
                Carpetas de campañas
            </div>
            <div class="col-auto ml-auto">
                <div class="btn-red" @click="agregarCarpeta">
                    <i class="icon-folder-plus text-white f-18" />
                </div>
            </div>
        </div>
        <div v-for="folder in carpetas" :key="folder.id" class="row mx-0 cr-pointer align-items-center pl-2 folders border-bottom" style="height:50px;" @click="setCarpeta(folder)">
            <div :class="`carpeta ${carpeta.id == folder.id ? 'bg-general' : ''}`" />
            <div :class="`col ${carpeta.id == folder.id ? 'text-general' : 'text-general2'}`">
                {{ folder.nombre }}
            </div>
            <div class="col-auto">
                <div :class="`br-12 px-2 ${carpeta.id == folder.id ? 'bg-general3 text-white' : 'bg-general2 text-white'}`">
                    {{ folder.campanas_count }}
                </div>
            </div>
            <i :class="`icon-angle-right ${carpeta.id == folder.id ? 'text-general' : 'text-gris'}`" />
        </div>
        <!-- Partials -->
        <modal ref="modalAnadirCarpeta" icon="carpeta_nueva" titulo="Crear Carpeta" no-aceptar adicional="Guardar" @adicional="crearCarpeta()">
            <div class="row mx-0 my-4 justify-center">
                <div class="col-10">
                    <p class="text-general f-12 pl-3">Nombre de la carpeta</p>
                    <el-input v-model="nombre" class="w-100" />
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones'
import { mapActions, mapGetters, mapMutations } from  'vuex'

export default {
    data(){
        return {
            carpeta_activa: null,
            nombre: ''
        }
    },
    computed: {
        ...mapGetters({
            carpeta: 'publicidad/notificaciones/carpeta',
            carpetas: 'publicidad/notificaciones/carpetas',
            tipo: 'publicidad/notificaciones/tipo',
        }),
    },
    mounted(){
        this.listarCarpetas()
    },
    methods: {
        ...mapActions({
            listarCarpetas: 'publicidad/notificaciones/listarCarpetas',
        }),
        ...mapMutations({
            setCarpeta: 'publicidad/notificaciones/setCarpeta',
        }),
        seleccionarCarpeta(carpeta){
            this.setCarpeta(carpeta)
        },
        agregarCarpeta(){
            this.nombre = ''
            this.$refs.modalAnadirCarpeta.toggle();
        },
        async crearCarpeta(){
            try {
                const model = {
                    nombre: this.nombre,
                    tipo: this.tipo
                }
                const { data } = await notificaciones.crearCarpetas(model);
                if (data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')
                    await this.listarCarpetas()
                    this.agregarCarpeta()
                }
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.folders{
    .carpeta{
        width: 8px;
        height: 30px;
        border-radius: 12px;
    }
}
</style>